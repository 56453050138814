import BarGraph from "./BarGraph";
import DownloadIcon from "@mui/icons-material/Download";

export default function Resume() {
  return (
    <div id="resume" className="min-h-screen flex-col text-center">
      <div className="text-5xl font-bold py-8" data-aos={"slide-down"}>
        Resume
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mt-7">
        <ol className="relative border-l  border-gray-700 ml-9 mb-6">
          <li className="mb-10" data-aos="zoom-in">
            <span className="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3  text-blue-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold  text-white ml-5">
              Full-stack developer at DNSE Securities Joint Stock Company
            </h3>
            <time className="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
              Oct 1st, 2022 - Now
            </time>
            <p className="mb-4 text-base font-normal  text-gray-400">
              Develop dynamic and responsive website, mobile applications with
              clean interface and simple. Develop project concepts and maintain
              optimal workflow. Participate in database design and api backend
              tasks. Carry out quality assurance unit tests to discover errors
              and optimize usability.
            </p>
          </li>
          <li className="mb-10" data-aos="zoom-out" data-aos-delay="600">
            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-900 bg-blue-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3 text-blue-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="mb-1 text-lg font-semibold text-white ml-5">
              Full-stack developer at MTT Software Company
            </h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-500">
              Jun 1st, 2017 - Sep 30th, 2022
            </time>
            <p className="text-base font-normal text-gray-400">
              Develop dynamic and responsive website, mobile applications with
              clean interface and simple. Develop project concepts and maintain
              optimal workflow. Participate in database design and api backend
              tasks. Carry out quality assurance unit tests to discover errors
              and optimize usability.
            </p>
          </li>
        </ol>

        <div className="flex flex-col text-center" data-aos="slide-up">
          <div className="text-3xl font-bold py-3">My Skills</div>
          <div className="ml-16 flex flex-col gap-8 mt-6">
            <BarGraph name={"JavaScript"} percent={80} />
            <BarGraph name={"NodeJS"} percent={75} />
            <BarGraph name={"ReactJS"} percent={75} />
            <BarGraph name={"React native"} percent={75} />
            <BarGraph name={"Python"} percent={60} />
            <BarGraph name={"Golang"} percent={60} />
            <BarGraph name={"Flutter"} percent={50} />
            <BarGraph name={"Postgres"} percent={65} />
            <BarGraph name={"MongoDB"} percent={65} />
          </div>

          {/* <a
            href={require("../../assets/files/Daniel Resume.pdf")}
            download={"Vuong's Resume"}
            className="inline-flex items-center px-6 py-2 mt-12 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
          >
            Download CV <DownloadIcon />
          </a> */}
        </div>
      </div>
    </div>
  );
}
